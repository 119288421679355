<template>
    <div class="view-photo" v-if="photo">
        <div class="download">
            <a class="btn" :href="photo.uri.original" target="_blank">
                <FontAwesomeIcon icon="download"/>
            </a>
        </div>
        <div class="close" @click="$router.push({name: 'Photos'})">
            <button class="btn">
                <FontAwesomeIcon icon="times"/>
            </button>
        </div>
        <div class="previous" @click="$emit('previous')">
            <button class="btn">
                <FontAwesomeIcon icon="arrow-alt-circle-left"/>
            </button>
        </div>
        <div class="photo">
            <img :src="uri" :key="uri">
        </div>
        <div class="next" @click="$emit('next')">
            <button class="btn">
                <FontAwesomeIcon icon="arrow-alt-circle-right"/>
            </button>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'ViewPhoto',
    computed: {
        uri() {
            const dimension = Math.max(
                document.documentElement.clientWidth || 0,
                document.documentElement.clientHeight || 0,
                window.innerWidth || 0,
                window.innerHeight || 0
            )
            if (dimension > 1920) return this.photo.uri.original
            else if (dimension > 1024) return this.photo.uri.xl
            else if (dimension > 512) return this.photo.uri.lg
            else if (dimension > 256) return this.photo.uri.md
            else if (dimension > 64) return this.photo.uri.sm
            else return this.photo.uri.xs
        }
    },
    props: {
        photo: Object
    },
    components: {
        FontAwesomeIcon
    },
    methods: {
        keyBindings(e) {
            switch (e.key) {
                case 'Escape':
                    this.$router.push({name: 'Photos'})
                    break
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            document.body.classList.add('overlay')
            vm.$store.commit('bindKeys', vm.keyBindings)
        })
    },
    beforeRouteLeave(to, from, next) {
        document.body.classList.remove('overlay')
        this.$store.commit('unbindKeys')
        next()
    }
}
</script>

<style scoped>
.view-photo {
    position: relative;
    background-color: #222;
    overflow: hidden;
    display: flex;
    height: 100%;
    width: 100%;
}
.next,
.previous {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.download {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 1rem 0;
}
.close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0 1rem 1rem;
}
.photo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn {
    color: #333;
    font-size: 2rem;
    background: none;
    border: none;
    margin: .25rem;
    padding: .25rem;
    cursor: pointer;
    transition: color .2s;
    display: inline-block;
}
.close:hover>.btn,
.next:hover>.btn,
.previous:hover>.btn {
    color: gray;
}
img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    user-select: none;
}
</style>
